import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUser } from '../../interfaces/user';
import { ApiRequestsService } from '../../services/api-requests.service';
import { AuthService } from '../../services/auth/auth.service';
import { CMI_OBJ } from '../model';
import { NotesPopupComponent } from '../notes-popup/notes-popup.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { QueryPopupComponent } from '../query-popup/query-popup.component';
import { environment } from '../../../environments/environment.prod';

declare var Scorm12API: any;

declare var Scorm2004API: any;

@Component({
  selector: 'app-course-popup-iframe',
  templateUrl: './course-popup-iframe.component.html',
  styleUrls: ['./course-popup-iframe.component.scss']
})
export class CoursePopupIframeComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;

  @Input() linkUrl: string = '';

  @Input() item: any = {};

  @Input() isDemo: boolean;

  @Input() programId: number;

  @Input() programname: string;

  cmiversion: string = '';

  sessionid: any = null;

  CMI_OBJ: any = {};

  private settings: any = {
    autocommit: true,
    autocommitSeconds: 60,
    lmsCommitUrl: this.api.baseURL + 'program/sendTrackingDataToLMS',//sendCmiObj
    dataCommitFormat: "json",//flattened/json/params
    logLevel: 1,
    autoProgress: true,
    selfReportSessionTime: false,
    alwaysSendTotalTime: true,
    xhrHeaders: {
      'authorization': 'Bearer ' + this.authService.token, "authfor": "W",
      "sessionid": this.sessionid,
      "cmiversion": this.cmiversion
    },
    onLogMessage: function (d: any) {
      // console.trace(d)
      // const win: any = window
      // console.log(win.API["cmi"]["interactions"],win.API["cmi"]);

    },
    // requestHandler: function (d: any) {
    //   console.log(d, 'requestHandler');
    // //   d.cmi.learner_id = "123456789"
    // console.warn(this.user);

    // },
    responseHandler: function (d: any) {
      // console.log(d, 'responseHandler');

    },
    requestHandler: this.sendRequest
  };

  private user: any = {
    student_id: "1234",
    student_name: "RO"
  };

  interval: any;

  intervalFlag: boolean = false;

  currentUser: CurrentUser;
  public playerBSubject: BehaviorSubject<any>
  constructor(private ngbModal: NgbModal, private authService: AuthService,
    private ngbActiveModal: NgbActiveModal, private api: ApiRequestsService,
  ) {
    this.currentUser = this.authService.currentUserValue?.Data;
    this.playerBSubject = new BehaviorSubject({})
    if (!environment.production) {
      // console.log('set to local');

      // this.settings.lmsCommitUrl = 'localhost:3000/program/sendTrackingDataToLMS'
      // this.settings.requestHandler = (l:any)=>{console.log(l);
      // }
    }
  }

  getPlayer() {
    // console.log(GetPlayer().GetVar("variablename"))
    Observable.bind({})
      .subscribe((player) => {
        console.log("getPlayer ", player);

        // this.playerBSubject.next(player);
      });
    return this.playerBSubject.asObservable();
  }

  ngOnInit(): void {

    this.user.student_id = this.item?.sessiondata?.userid
    this.user.student_name = this.currentUser.uname
    this.sessionid = this.item?.sessiondata?.sessionid
    this.settings.xhrHeaders.sessionid = this.sessionid;
    this.settings.xhrHeaders.authorization = 'Bearer ' + this.authService.token;
    this.CMI_OBJ = this.item?.cmiobj || CMI_OBJ;

    if (this.authService.token) {
      // console.log('token present');
      this.scorm_Try_again();
    } else {
      this.closeModal(false)
    }
    localStorage.setItem("token", 'Bearer ' + this.authService.token);
    localStorage.setItem("item", JSON.stringify(this.item));
    localStorage.setItem("userId", this.item?.sessiondata?.userid);
    localStorage.setItem("sessionId", this.item?.sessiondata?.sessionid);
  }
  closeModal(isclose = true) {
    const cls = isclose ? { status: 'close' } : {};
    this.ngbActiveModal.close(cls);
    this.linkUrl = '';
    const win: any = window;
    // console.log(win['API']['cmi']['core']);
    // console.log(JSON.stringify(win['API']['cmi']['core']));
    // console.log(JSON.stringify(win['API']['cmi']));
    const cmiData = {
      cmi: JSON.parse(JSON.stringify(win['API']['cmi'])),
      // cmiOrg: win['API']['cmi']
    }
    // console.log(cmiData);
    this.saveCMIobjOnClose();
    // this.sendCmiOnClose(cmiData)
    // sendTrackingDataToLMS1("2004");
  }

  saveCMIobjOnClose() {
    const win: any = window;
    // console.log(window);
    let studentName = win?.API?.cmi?.core?.student_name;
    // console.log(this.cmiversion,studentName);
    studentName = win?.API_1484_11?.cmi?.learner_name;
    // console.log(this.cmiversion,studentName);

  }

  scorm_Try_again() {

    // SCORM 1.2
    let API: any = "API";
    window[API] = new Scorm12API(this.settings);


    //SET 1.2 User Details
    // window[API][cmiKeys.CMI][cmiKeys.learner_id] = user.learner_id;

    this.scorm2004(this.settings);
    // this.scorm2004API();
    // console.log('test', window);
    // this.szenadam();
    this.checkUserSetOrNot();
    // checkUserSetOrNot()
    // this.test1_2();

  }

  scorm2004(settings: any) {

    // SCORM 2004
    let API_1484_11: any = "API_1484_11";
    window[API_1484_11] = new Scorm2004API(settings);
    // this.sCORMService.SCORM.initialize()
    // let c = this.sCORMService.SCORM.findApi(window);
    // console.log(c,'window');

  }

  setUserCMIData(isCore = true) {
    // console.log('setUserCMIData', isCore);

    // const interactions = {
    //   "interactions": CMI_OBJ['cmi']['interactions'],
    //   "core": CMI_OBJ['cmi']['core'],
    //   "suspend_data": CMI_OBJ['cmi']['suspend_data']
    // };
    if (this.CMI_OBJ['cmi']) {
      const win: any = window;
      win.API.loadFromJSON(this.CMI_OBJ['cmi']);
      win.API_1484_11.loadFromJSON(this.CMI_OBJ['cmi']);
    } else {

      const win: any = window;
      // console.log(win);


      if (isCore) {
        this.cmiversion = 'cmi1_2';
        this.settings.xhrHeaders.cmiversion = this.cmiversion;
        const userData = {
          "core": {
            "student_id": this.user.student_id,
            "student_name": this.user.student_name,

          }
        };
        // win['API']['cmi']['core']['student_id'] = this.user.student_id;
        // win['API']['cmi']['core']['student_name'] = this.user.student_name;
        win.API.loadFromJSON(userData);
      } else {
        this.cmiversion = 'cmi2004';
        this.settings.xhrHeaders.cmiversion = this.cmiversion;
        const userData = {
          "core": {
            "learner_id": this.item?.sessiondata?.userid || 0,
            "learner_name": this.currentUser.uname,

          }
        };
        // win['API_1484_11']['cmi']['learner_id'] = this.user.student_id;
        // win['API_1484_11']['cmi']['learner_name'] = this.user.student_name;
        win['API_1484_11'].loadFromJSON(userData['core']);
      }
    }
  }

  checkUserSetOrNot() {
    const win: any = window;
    console.log('checkUserSetOrNot', win['API']['cmi']['core']['student_id'], win['API_1484_11']['cmi']['student_id']);
    let count = 0, flag = false;
    this.interval = setInterval(() => {
      count++;
      flag = true;
      if (!win['API']['cmi']['core']['student_id']) {
        // console.log('core', 'checkUserSetOrNot', win['API']['cmi']['core']['student_id']);

        flag = false;
        this.cmiversion = 'cmi1_2';
        this.settings.xhrHeaders.cmiversion = this.cmiversion;
        console.log(244, this.settings.xhrHeaders);

        this.setUserCMIData(true)
      }

      if (!win['API_1484_11']['cmi']['learner_id'] || !win['API_1484_11']['cmi']['student_id']) {
        // console.log(count, '!core', 'checkUserSetOrNot', win['API_1484_11']['cmi']['learner_id']);

        flag = false;
        this.cmiversion = 'cmi2004';
        this.settings.xhrHeaders.cmiversion = this.cmiversion;
        console.log(255, this.settings.xhrHeaders);
        this.setUserCMIData(false)
      }
      this.intervalFlag = flag;//count > 30 || flag
      if (!this.intervalFlag) {
        clearInterval(this.interval)
      }
    }, 50);
  }

  sendRequest(cmiobj: any) {
    console.warn(1, cmiobj, 'sendRequest', this.settings);
    // console.log(document.getElementsByTagName('tspan'));

    const endPoint = '/program/sendTrackingDataToLMS'
    let cmi = JSON.parse(JSON.stringify(cmiobj));
    const params = { "sessionid": this.item?.sessiondata?.sessionid, "cmiobj": cmi };
    cmiobj = params;
    return params


  }

  sendCmiOnClose(cmiobj: any) {
    console.warn(2, cmiobj, 'sendRequest', this.settings);
    const endPoint = 'program/sendTrackingDataToLMS';
    let cmi = JSON.parse(JSON.stringify(cmiobj));
    const params = { "cmiobj": cmi };
    this, this.api.postApiCall(endPoint, params, {
      "sessionid": this.sessionid,
      "cmiversion": this.cmiversion, test: 'onclose'
    }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        // console.log(res);
      }
    }, (err) => { }, () => { })
  }

  openNotePopup(sessionid: number) {

    const ngbModalRef = this.ngbModal.open(NotesPopupComponent, { windowClass: 'feedback-pop', backdropClass: 'feedback-pop-backdrop', centered: false, backdrop: 'static', size: 'lg', scrollable: true });
    ngbModalRef.componentInstance.sessionid = sessionid;
    ngbModalRef.componentInstance.item = this.item;


    ngbModalRef.result.then((result: any) => {
      // console.log(result);

      if (result && result?.status == "close") {
        // console.log('closed');
        // this.getUserAssignedProgramWiseSessionList(this.programid);
      }
    });
  }

  openQueryPopup(sessionid: number) {
    const ngbModalRef = this.ngbModal.open(QueryPopupComponent, { windowClass: 'feedback-pop', backdropClass: 'feedback-pop-backdrop', centered: false, backdrop: 'static', size: 'lg', scrollable: true });
    ngbModalRef.componentInstance.sessionid = sessionid;
    ngbModalRef.componentInstance.item = this.item;
    ngbModalRef.componentInstance.programId = this.programId;
    ngbModalRef.componentInstance.programname = this.programname
    console.log(this.item);


    ngbModalRef.result.then((result: any) => {
      if (result && result?.status == "close") {
        // console.log('closed');
        // this.getUserAssignedProgramWiseSessionList(this.programid);
      }
    });

  }
  getLocalStorageItems() {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const sessionId = localStorage.getItem("sessionId");
    let item = localStorage.getItem("item");

    if (item) {
      item = JSON.parse(item);
    }
    // localStorage.removeItem("token");
    // localStorage.removeItem("userId");
    // localStorage.removeItem("item");

    return { item, token, userId, sessionId };
  }

  // ngAfterViewInit() {
  //   console.log(this.iframe);
  //   const nativeEl =  this.iframe.nativeElement;

  //   if ( (nativeEl.contentDocument || nativeEl.contentWindow.document).readyState === 'complete' )        {
  //       nativeEl.onload = this.onIframeLoad.bind(this);
  //   } else {
  //     if (nativeEl.addEventListener) {
  //       nativeEl.addEventListener('load', this.onIframeLoad.bind(this), true);
  //     } else if (nativeEl.attachEvent) {
  //       nativeEl.attachEvent('onload', this.onIframeLoad.bind(this));
  //     }
  //   }
  // }


  // onIframeLoad() {
  //   const base64String = this.iframe.nativeElement.contentWindow.document.body.innerHTML;
  //   console.log(this.iframe.nativeElement.contentWindow.document.body.children[1].currentSrc);
  // }
}
